
import { HelperMethods } from '@/utils/helper'
export default {
  computed: {
    expiryDetails() {
      let expiryDetails = { show: false, date: '' }
      if (!this.$store.state.user.user) {
        return expiryDetails
      }
      const expiryDate = new Date(this.$store.state.user.user.expiry_date)
      // Show expiry banner if the expiry date is less than or equal to 60 days
      expiryDetails.show =
        this.dateDiffInDays(expiryDate) <= 60 &&
        !this.$store.state.user.isRenewBannerDismissed
      // Date formatting
      // TODO - Should have Helper utility method to handle all these.
      expiryDetails.date =
        expiryDate.toLocaleString('default', { month: 'long' }) +
        ' ' +
        expiryDate.getDate() +
        ', ' +
        expiryDate.getFullYear() +
        ' ' +
        expiryDate.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        }) +
        ' (' +
        HelperMethods.timeZoneAbbreviated() +
        ')'
      return expiryDetails
    },
    renewURL() {
      return HelperMethods.urlWithRedirect(
        process.env.accountsURL + '/accounts/renew-access/'
      )
    },
  },
  methods: {
    dateDiffInDays(expiryDate) {
      const today = new Date()
      // Converting time difference to days.
      return (expiryDate.getTime() - today.getTime()) / (1000 * 3600 * 24)
    },
    closeExpiryBanner() {
      this.$store.commit('user/SET_RENEW_DISMISSED', true)
    },
    navigateToRenew() {
      window.open(this.renewURL, '_self')
    },
  },
}
