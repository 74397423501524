
export default {
  props: {
    showCaret: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'link',
    },
    id: {
      type: String,
      default: 'dropdown-with-slots',
    },
  },
  created() {
    // Tonotify this component to open the dropdown menu incase of notification updates banner
    this.$nuxt.$on('openDropdownMenu', () => {
      if (this.$refs[this.id]) {
        this.$refs[this.id].show()
      }
    })
  },
  methods: {
    scrollToTop() {
      // To reset the dropdown menu scroll position on hide.
      // Doing it on hide() as show() needs some delay
      const dropdownMenu = this.$refs[this.id].$refs.menu
      dropdownMenu.scrollTop = 0
    },
  },
}
