import Vue from 'vue'
export const state = () => ({
  faqs: [],
  activeSubGroup: null,
  activeFAQs: [],
})

export const mutations = {
  SET_FAQS(state, payload) {
    state.faqs = payload.FAQ
  },
  SET_DEFAULT_ACTIVE_SUB_GROUP(state) {
    state.activeFAQs = []
    state.activeSubGroup = state.faqs[0].subGroups[0]
  },
  SET_ACTIVE_SUB_GROUP(state, payload) {
    state.activeFAQs = []
    state.activeSubGroup = payload
  },
  RESET_ACTIVE_SUB_GROUP(state) {
    state.activeFAQs = []
    state.activeSubGroup = null
  },
  UPDATE_FEEDBACK_SUBMIT(state, payload) {
    const activeSubgroup = Object.assign(
      {},
      state.faqs
        .flatMap((group) => group.subGroups)
        .find((item) => item.name === state.activeSubGroup.name)
    )
    activeSubgroup.data.find(
      (qAndA) => qAndA.pk === payload.id
    ).isFeedbackSubmitted = true
    state.activeSubGroup.data = []
    state.activeSubGroup.data = activeSubgroup.data
  },
  UPDATE_ACTIVE_FAQS(state, faqId) {
    if (!faqId) {
      state.activeFAQs = []
      return
    }
    if (state.activeFAQs.includes(faqId)) {
      state.activeFAQs = state.activeFAQs.filter((pk) => pk !== faqId)
    } else {
      state.activeFAQs.push(faqId)
    }
  },
}

export const actions = {
  async FETCH_FAQS({ commit }) {
    return new Promise((resolve) => {
      const url = '/api/v1/users/faq/'
      this.$axios.get(url, { withCredentials: true }).then((response) => {
        commit('SET_FAQS', response)
        resolve()
      })
    })
  },
  async SUBMIT_FAQ_FEEDBACK({ commit }, feedback) {
    return new Promise((resolve) => {
      const url = '/api/v1/users/faq/' + feedback.id + '/'
      const reqPayload = {
        feedback: feedback.text,
        positive: feedback.type === 'positive' ? true : false,
      }
      this.$axios
        .post(url, reqPayload, { withCredentials: true })
        .then((response) => {
          commit('UPDATE_FEEDBACK_SUBMIT', feedback)
          resolve()
        })
    })
  },
}
