
import { WaitforAuth } from 'compile-ui'
import SideNavBar from '@/components/SideNavBar.vue'
import ExpiryBanner from '@/components/ExpiryBanner.vue'
export default {
  components: {
    WaitforAuth,
    SideNavBar,
    ExpiryBanner,
  },
  data() {
    return {
      version: null,
      newVersion: false,
    }
  },
  watch: {
    $route: {
      handler() {
        this.$store.commit('SET_APPERROR', false)
        this.$store.dispatch('user/GET_USER_PROFILE', 'all')
      },
    },
  },
  computed: {
    accountsURL() {
      return process.env.accountsURL
    },
    adminURL() {
      return process.env.adminURL
    },
  },
  mounted() {
    this.$store.dispatch('user/GET_USER_PROFILE', 'all')
    this.versionCheck()
    setInterval(this.versionCheck, 60000)
  },
  methods: {
    reloadApp() {
      window.location.reload()
    },
    async versionCheck() {
      // Assumes the app is SPA, and the index page js file hashes change
      const url = window.location.protocol + '//' + window.location.host + '/'
      if (this.version === null) {
        this.version = await this.$axios.get(url)
      } else {
        const version = await this.$axios.get(url)
        if (this.version !== version) {
          this.newVersion = true
          console.log('New version available!')
        }
      }
    },
  },
}
