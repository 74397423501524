export const state = () => ({
  apiBase: null,
  accountsBase: null,
})

export const mutations = {
  TOGGLE_STAGE(state) {
    state.apiBase = state.apiBase === null ? process.env.stageBaseUrl : null
  },
}
