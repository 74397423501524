
import { HelperMethods } from '@/utils/helper'
export default {
  computed: {
    userData() {
      return this.$store.state.user.user
    },
    signoutURL() {
      return HelperMethods.urlWithRedirect(
        process.env.accountsURL + '/accounts/logout/'
      )
    },
    changePasswordURL() {
      return HelperMethods.urlWithRedirect(
        process.env.accountsURL + '/accounts/password/change/'
      )
    },
    userAdminURL() {
      return process.env.adminURL + '/user-admin/'
    },
  },
  methods: {
    navigateToChangePassword() {
      window.open(this.changePasswordURL, '_self')
    },
    navigateToSignOut() {
      window.open(this.signoutURL, '_self')
    },
  },
}
