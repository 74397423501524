
import UserDropdown from '@/components/UserDropdown.vue'
import Notification from '@/components/Notification.vue'
import { HelperMethods } from '@/utils/helper'

export default {
  components: {
    UserDropdown,
    Notification,
  },
  computed: {
    userData() {
      return this.$store.state.user.user
    },
    embedPublicUser() {
      return this.$store.state.user.embedPublicUser
    },
    contactUsURL() {
      return HelperMethods.urlWithRedirect(
        process.env.accountsURL + '/accounts/contact-us/'
      )
    },
  },
  methods: {
    navigateToContactUs() {
      window.open(this.contactUsURL, '_self')
    },
  },
}
