
import DropdownWithSlots from '@/components/DropdownWithSlots.vue'
import { HelperMethods } from '@/utils/helper'
import { cloneDeep } from 'lodash'
export default {
  data() {
    return {
      updateNotifProduct: null,
    }
  },
  components: {
    DropdownWithSlots,
  },
  computed: {
    notifications() {
      if (this.updateNotifProduct) {
        const allNotifications = cloneDeep(
          this.$store.state.user.user.notifications
        )
        const filteredItems = allNotifications.filter((item) =>
          this.updateNotifProduct.notif_status.notifications.includes(item.id)
        )
        const remainingItems = allNotifications.filter(
          (item) =>
            !this.updateNotifProduct.notif_status.notifications.includes(
              item.id
            )
        )
        // Group all the notification related to the product but highlight only the update notifications and not the new ones.
        filteredItems.forEach(
          (item) => (item.highlight = item.category === 'update' ? true : false)
        )
        return filteredItems.concat(remainingItems)
      }
      return this.$store.state.user.user.notifications
    },
    hasUnReadNotifications() {
      return this.notifications?.some((notification) => !notification.read)
    },
  },
  created() {
    this.$nuxt.$on('openDropdownMenu', (product) => {
      if (this.$refs.notificationDropdown) {
        this.updateNotifProduct = Object.assign({}, product)
        setTimeout(() => {
          this.$store
            .dispatch(
              'user/UPDATE_NOTIFICATION_STATUS',
              product.notif_status.notifications
            )
            .then(() => {
              this.$store.commit('user/UPDATE_PRODUCT_NOTIF_STATUS', product)
            })
        }, 500)
      }
    })
  },
  methods: {
    notificationClickHandler(notification) {
      //API call to mark the notification read on click of the item
      if (!notification.read) {
        this.$store.dispatch('user/UPDATE_NOTIFICATION_STATUS', [
          notification.id,
        ])
      }
      //If notification doesn't have link then no action required.
      if (!notification.link) return
      //If notification link is available then check the origin and decide to route or open new tab.
      if (HelperMethods.isDifferentDomain(notification.link)) {
        window.open(notification.link, '_blank')
      } else {
        const url = new URL(notification.link)
        const routePath = url.pathname + url.hash
        this.$router.push(routePath)
        // To set the active route path to highlight the item in side nav bar
        // As the pages are loading in multiple layouts and the navbar is part of each of it.
        // If we dispatch the custom event immediate to route change the custom event is listened
        // at the previous layout navbar instance and not the current one. So, with some delay
        // we can overcome that issue.
        setTimeout(() => {
          this.$nuxt.$emit('setActiveRoutePath')
        }, 500)
      }
    },
    onNotificationBtnClick() {
      this.updateNotifProduct = null
    },
    notificationDate(notification) {
      return HelperMethods.getNotificationDateDiff(notification.created)
    },
  },
}
