import { render, staticRenderFns } from "./AllowNoAuth.vue?vue&type=template&id=9fa7fade"
import script from "./AllowNoAuth.vue?vue&type=script&lang=js"
export * from "./AllowNoAuth.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderBar: require('/Users/anasr/Repos/web/console/components/HeaderBar.vue').default,SideNavBar: require('/Users/anasr/Repos/web/console/components/SideNavBar.vue').default,ExpiryBanner: require('/Users/anasr/Repos/web/console/components/ExpiryBanner.vue').default})
