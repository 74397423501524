export default function ({ route, redirect, store }) {
  if (store.state.user.isUnderMaintenance) {
    // Redirect the user to the maintenance page if the site is under maintenance.
    if (route.path !== '/maintenance/') {
      redirect('/maintenance/')
    }
  } else {
    // If the site is not under maintenance, redirect to the home page if the user requests the maintenance page.
    if (route.path === '/maintenance/') {
      redirect('/')
    }
  }

  // Add all future redirects here
  const redirects = [
    {
      from: /^\/solutions/i,
      to: '/sample-dashboards/',
    },
    {
      from: /^\/products/i,
      to: '/data/',
    },
    {
      from: /^\/help/i,
      to: '/guides/',
    },
  ]
  //Custom redirects with hash
  const redirectsWithHash = {
    '#CMS.CMS_MEDICARE_CLOSED_CLAIMS':
      '/schema/#CMS.ALL_MEDICARE_CLOSED_CLAIMS_READYDATA',
    '#CMS.ALL_MEDICARE_COMPLETE_CLAIMS_READYDATA':
      '/schema/#CMS.ALL_MEDICARE_CLOSED_CLAIMS_READYDATA',
  }

  const hashRedirect = redirectsWithHash[route.hash]
  if (hashRedirect) {
    redirect(hashRedirect)
    return
  }

  redirects.forEach((redirectURL) => {
    if (redirectURL.from.test(route.path)) {
      redirect(route.path.replace(redirectURL.from, redirectURL.to))
    }
  })

  // For better GA4-Google Analytics
  if (route.path.slice(-1) !== '/') {
    redirect(301, route.path + '/')
  }
}
