/** Global utility class */
export class HelperMethods {
  // Method to append the redirect url
  static urlWithRedirect(url) {
    return url + '?back=' + encodeURIComponent(window.location.href)
  }
  static isDifferentDomain(link) {
    const url = new URL(link)
    return url.origin !== window.location.origin ? true : false
  }

  static getNotificationDateDiff(date) {
    const today = new Date()
    const diff = Math.floor((today - new Date(date)) / (1000 * 60 * 60 * 24))
    switch (true) {
      case diff === 0:
        return 'Today'
      case diff <= 7:
        return `${diff}d`
      case diff <= 14:
        return '2w'
      case diff <= 21:
        return '3w'
      case diff <= 28:
        return '4w'
      default:
        return new Date(date).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
        })
    }
  }

  static setRouteHash(route, hashPath, router = null) {
    hashPath = hashPath.replaceAll(' ', '_')
    let newRoute = route.path + '#' + hashPath
    //TODO: Should replace history.pushstate with $router.replace
    //With history.pushState actual router object is not updated.
    history.pushState({}, null, newRoute)
    if (router) {
      //Replacing the router hash
      router.replace({
        path: route.path,
        hash: hashPath,
      })
    }
  }

  static timeZoneAbbreviated() {
    const { 1: timeZoneName } = new Date().toString().match(/\((.+)\)/)
    if (timeZoneName.includes(' ')) {
      return timeZoneName
        .split(' ')
        .map(([firstChar]) => firstChar)
        .join('')
    } else {
      return timeZoneName
    }
  }

  static initializeGtag(userId, shareKey = '') {
    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    gtag('set', { user_id: userId })
    gtag('set', { share_key: shareKey })
  }
}
