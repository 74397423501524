
export default {
  name: 'menu-nav-bar',
  created() {
    // Registering the event listner
    this.$nuxt.$on('setActiveRoutePath', () => {
      this.setActiveRoutePath()
    })
  },
  mounted() {
    this.setActiveRoutePath()
  },
  methods: {
    /**To set nav-item(option) active */
    isItemActive(routePath) {
      return routePath === this.activePath
    },
    /**Route navigation */
    navigateTo(routePath) {
      this.activePath = routePath
      this.$router.push(routePath)
    },
    hasProducts(category) {
      /**Hide the nav-item(option) if the the catagory doesn't have any items to show on the right pane */
      if (category === 'Sample-dashboards') {
        return this.$store.state.user.products.SampleDashboards.length > 0
      }
      return this.$store.state.user.products[category].length > 0
    },
    hasRouteHash(category) {
      return this.productsNameByCategory(category).includes(
        this.$router.currentRoute.hash
      )
    },
    hasAdminAppsAccess() {
      return (
        this.$store.state.user.user.is_compile_user &&
        this.$store.state.user.products['Admin'].length > 0
      )
    },
    setActiveRoutePath() {
      /**If user is directly landing on any specific url without any user iteraction, OR
       * If user routes via notifications.
       * We need to set the nav-item(option) active class.
       * Deciding it based on routepath  */
      if (this.$router.currentRoute.path.includes('viz')) {
        if (this.hasRouteHash('Guides')) {
          this.activePath = '/guides'
          return
        }
        if (this.hasRouteHash('Sample Dashboards')) {
          this.activePath = '/sample-dashboards'
          return
        }
      } else {
        const pathMatch = Object.keys(this.routePathMap).find((key) =>
          this.$router.currentRoute.path.includes(key)
        )
        this.activePath = pathMatch
          ? this.routePathMap[pathMatch]
          : this.$router.currentRoute.path
      }
    },
    productsNameByCategory(category) {
      if (
        this.$store.state.user.rawProductsData.filter(
          (product) => product.category === category
        ).length > 0
      ) {
        return this.$store.state.user.rawProductsData.map((product) =>
          product.category === category ? new URL(product.primary_url).hash : ''
        )
      } else {
        return []
      }
    },
  },
  data() {
    return {
      activePath: this.tabIndex,
      routePathMap: {
        guides: '/guides',
        schema: '/data',
        'sample-dashboards': '/sample-dashboards',
        'release-notes': '/release-notes',
        admin: '/admin',
        '/': '/data',
      },
    }
  },
  destroyed() {
    // De-registering the event listner to avoid multiple instances.
    this.$nuxt.$off('setActiveRoutePath')
  },
}
