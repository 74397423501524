import lunr from 'lunr'

export const state = () => ({
  data: null,
  userAuthenticated: false,
  schema: null,
  schemaComingSoon: null,
  activeTable: null,
  activeField: null,
  // Adding an order is not necessary, ones without order will be added in the end
  groupOrder: ['PATIENT READYDATA', 'PROVIDER 360 READYDATA', 'REFERENCE'],
  subGroupOrder: {
    'PATIENT READYDATA': [
      'OPEN CLAIMS READYDATA',
      'MULTI-PAYER CLOSED CLAIMS READYDATA',
      'ALL MEDICARE CLOSED CLAIMS READYDATA',
      'ELECTRONIC HEALTH RECORDS',
      'OTHER',
    ],
    'PROVIDER 360 READYDATA': [
      'AFFILIATIONS',
      'ACCOUNT',
      'HCP',
      'CLINICAL TRIALS',
      'PUBLICATION',
      'OPEN PAYMENTS',
    ],
    REFERENCE: ['ENTITY', 'CROSSWALK', 'OTHER'],
  },
  LoadingMessage: 'Please wait while we authenticate you',
  copiedCode: null,
  subgroupDescriptions: {
    PRIME_PLD:
      "McKesson Compile's enhanced Anonymous Patient Level Data (abbreviated as APLD or just PLD) is claims layout with integrated affiliations and smart cleanups to power your analytical projects",
  },
  cmsClosedClaimsTable: {
    name: 'ALL MEDICARE CLOSED CLAIMS READYDATA',
    label: 'ALL_MEDICARE_CLOSED_CLAIMS_READYDATA',
    fields: [],
    is_view: false,
    relations: [],
    description:
      'Aggregated data summaries of 100% closed Medicare claims from Centers for Medicare and Medicaid Services (CMS).<br/> <br/>Transactional data is not available. Data must be summarized to the patient group of 10 or more.',
    table_count: 0,
    patients_count: '68M',
    last_updated: '',
    database_name: '',
    source_update_frequency: '',
    sf_schema_name: 'CMS',
  },
  nodeOnlyTables: ['ALL MEDICARE CLOSED CLAIMS READYDATA'],
})

export const mutations = {
  SET_USER(state, payload) {
    state.data = payload
  },
  USER_IS_AUTHENTICATED(state, payload) {
    state.userAuthenticated = payload
  },
  VALID_SHARE_KEY(state, payload) {
    state.validsharekey = payload
  },
  clearActives(state) {
    state.activeTable = null
    state.activeschema = null
    state.activeField = null
  },
  setActives(state, tableID) {
    if (tableID) {
      if (tableID.split('.').length === 3) {
        state.activeField = state.schema.fieldlookup[tableID]
        tableID = tableID.split('.').slice(0, 2).join('.')
      } else {
        state.activeField = null
      }
      const schema = state.schema.groupLookup[tableID]
      state.activeTable = state.schema.tablelookup[tableID][0]
      state.activeschema = schema
    }
  },
  setActiveSchema(state, schema) {
    state.activeschema = schema
    state.activeTable = Object.entries(
      state.schema.tablegrouping[schema]
    )[0][1][0]
    const currentPath = window.location.href.replace(window.location.origin, '')
    const hash =
      '#' + state.activeTable.sf_schema_name + '.' + state.activeTable.label
    let newRoute = ''
    if (window.location.hash) {
      newRoute = currentPath.substring(0, currentPath.indexOf('#')) + hash
    } else {
      newRoute = currentPath + hash
    }
    history.pushState({}, null, newRoute)
  },
  setActiveField(state, field) {
    state.activeField = field
  },
  setSchemaMeta(state, schemaMeta) {
    let computedSchema = {
      tablelookup: null,
      tablegrouping: null,
      groupLookup: null,
      fieldlookup: null,
      schema_json: schemaMeta.schema,
      created: new Date(schemaMeta.created_at.split(' ')[0]),
    }
    state.schema = computedSchema
  },
  setSchema(state, schema) {
    let computedSchema = Object.assign({}, state.schema)
    computedSchema['tablelookup'] = {}
    computedSchema['tablegrouping'] = {}
    computedSchema['groupLookup'] = {}
    computedSchema['fieldlookup'] = {}
    let searchData = []
    const actualGroups = Object.keys(schema.schema.schemas)
    const customOrder = state.groupOrder.filter(
      (v) => v in schema.schema.schemas
    )
    const difference = actualGroups.filter((x) => !customOrder.includes(x))
    customOrder.concat(difference).forEach((group_name, order_index) => {
      const group_data = schema.schema.schemas[group_name]
      //Adding All Medicare Closed Claims ReadyData under PATIENT READYDATA--> Others.
      //JIRA: CONS-5
      if (group_name === 'PATIENT READYDATA') {
        group_data['ALL MEDICARE CLOSED CLAIMS READYDATA'] = {
          CMS: {
            ALL_MEDICARE_CLOSED_CLAIMS_READYDATA: state.cmsClosedClaimsTable,
          },
        }
      }
      computedSchema['tablegrouping'][group_name] = {}
      const actualSubGroups = Object.keys(group_data)
      const customSubGroupOrder = state.subGroupOrder[group_name].filter(
        (v) => v in group_data
      )
      const difference = actualSubGroups.filter(
        (x) => !customSubGroupOrder.includes(x)
      )
      customSubGroupOrder.concat(difference).forEach((sub_group_name) => {
        const sub_group_data = group_data[sub_group_name]
        Object.entries(sub_group_data).forEach((sf_schema) => {
          const [sf_schema_name, tables] = sf_schema
          Object.entries(tables).forEach((table) => {
            const [table_name, table_data] = table
            // converting table and field descriptions to markdown
            if (table_data.hasOwnProperty('description')) {
              table_data.description = table_data.description
            }
            table_data.sf_schema_name = sf_schema_name
            table_data.is_view = false
            // table_data.hasOwnProperty('is_view') && table_data.is_view === true
            table_data.fields.forEach((field) => {
              if (
                field.hasOwnProperty('description') &&
                field.description !== '' &&
                field.description !== null
              ) {
                field.description = field.description
              }
              computedSchema.fieldlookup[
                sf_schema_name + '.' + table_data.label + '.' + field.name
              ] = field
            })
            try {
              computedSchema.tablegrouping[group_name][sub_group_name].push(
                table_data
              )
            } catch (err) {
              computedSchema.tablegrouping[group_name][sub_group_name] = [
                table_data,
              ]
            }
            computedSchema.tablelookup[
              sf_schema_name + '.' + table_data.label
            ] = [table_data, order_index]
            computedSchema.groupLookup[
              sf_schema_name + '.' + table_data.label
            ] = group_name

            // for schema wide search
            let search_data = {
              id: sf_schema_name + '.' + table_data.label,
              description: table_data.description,
              label: table_data.label,
            }
            searchData.push(search_data)

            table_data.fields.forEach((field) => {
              let search_data = {
                id: sf_schema_name + '.' + table_data.label + '.' + field.name,
                description: field.description,
                label: field.name,
              }
              searchData.push(search_data)
            })
          })
        })
      })
    })
    state.schema.tablelookup = computedSchema['tablelookup']
    state.schema.tablegrouping = computedSchema['tablegrouping']
    state.schema.groupLookup = computedSchema['groupLookup']
    state.schema.fieldlookup = computedSchema['fieldlookup']

    var skipField = function (fieldName, fn) {
      return function (token, i, tokens) {
        if (token.metadata['fields'].indexOf(fieldName) >= 0) {
          return token
        }
        return fn(token, i, tokens)
      }
    }

    lunr.stemmer = skipField('label', lunr.stemmer)
    state.searchTableLookup = lunr(function () {
      this.ref('id')
      this.field('description')
      this.field('label')

      searchData.forEach(function (doc) {
        this.add(doc)
      }, this)
    })
  },
  setChangelog(state, changelog) {
    state.changelog = changelog
  },
  setComingSoonSchema(state, payload) {
    state.schemaComingSoon = payload
  },
  setLoadingMessage(state, payload) {
    state.LoadingMessage = payload
  },
  setCopiedCOde(state, payload) {
    state.copiedCode = payload
  },
}

export const actions = {
  GET_USER_PROFILE({ commit, state }, route) {
    if (state.data == null) {
      let self = this
      let apiURL = process.env.rootURL
      return new Promise((resolve, reject) => {
        let url = apiURL + '/api/v1/schema/?fmt=json'
        if (route.route.query.s !== undefined) {
          url = url + '&s=' + route.route.query.s
        }
        self.$axios
          .get(url, {
            withCredentials: true,
          })
          .then((response) => {
            if (response && response.user) {
              if (response.user === 'ShareKey') {
                commit('USER_IS_AUTHENTICATED', true)
                commit('VALID_SHARE_KEY', route.route.query.s)
              } else {
                let name = response.user.name
                name =
                  name !== null && name.length > 0
                    ? name
                    : response.user.username
                const userObject = Object.assign({}, response.user, { name })
                commit('SET_USER', userObject)
                commit('USER_IS_AUTHENTICATED', true)
                // sentry set user context
                this.$sentry.setUser({ email: userObject.email })
                this.$sentry.setContext('userinfo', {
                  hashid: userObject.hashid,
                })

                resolve(response)
              }
              let v = Object.assign(response)
              commit('setSchemaMeta', v)
            } else {
              let errorMessage =
                'The link has expired. Please contact your sales representative or email  <a href="mailto:compile_sales@mckesson.com" class="alert-link">compile_sales@mckesson.com</a> for a new link.'
              commit(
                'SET_APPERROR',
                {
                  status: true,
                  message: errorMessage,
                  summary: '',
                  level: 'error',
                  isHTML: true,
                  showCTASection: false,
                },
                { root: true }
              )
            }
          })
      })
    }
  },
}
