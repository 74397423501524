import axios from 'axios'
const axiosInstance = axios.create({
  baseURL: process.env.baseUrl,
  browserBaseURL: process.env.baseUrl,
  withCredentials: true,
})

export default function ({ app, store, redirect }, inject) {
  inject('axios', axiosInstance)
  app.$axios = axiosInstance

  const baseURL =
    store.state.apiserver.apiBase !== null
      ? store.state.apiserver.apiBase
      : axiosInstance.defaults.baseURL
  const accountsURL =
    store.state.apiserver.accountsBase !== null
      ? store.state.apiserver.accountsBase
      : process.env.accountsURL

  // Request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      // Log the request URL
      console.log(`Making request to ${encodeURI(config.url)}`)
      // config.timeout = 60000
      if (store.state.apiserver.apiBase !== null) {
        config.baseURL = store.state.apiserver.apiBase
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.response.use(
    (response) => {
      return response?.data ?? response
    },
    (error) => {
      const code = parseInt(error.response && error.response.status)
      const url = window.location.href
      const words = store.state.user.allowNoAuthUrlList
      const noAuthAllowed = words.some((word) => url.includes(word))
      if (code > 400 && code < 404 && !noAuthAllowed) {
        let redirectURL = accountsURL + '/users/~redirect/?next='
        let slug = ''
        if (!process.server && url) {
          // eslint-disable-next-line no-console
          console.log(encodeURI(url))
          slug = url
        } else {
          const url = error.response.config.url
          slug = url.replace(baseURL, '')
        }
        redirectURL = redirectURL + encodeURIComponent(slug)
        redirect(redirectURL)
      } else if (code === 404) {
        store.commit('SET_APPERROR', {
          status: true,
          message: 'Sorry, this page does not exist.',
          summary: 'Does not exist',
          level: 'missing',
          error,
          showCTASection: true,
        })
      } else if (noAuthAllowed) {
        store.commit('user/EMBED_PUBLIC_USER', true)
      } else {
        // eslint-disable-next-line
        if (error.code === 'ECONNABORTED' || !error.status) {
          store.commit('SET_APPERROR', {
            status: true,
            message: 'Error fetching data',
            summary: 'Error',
            level: 'warning',
            error,
            showCTASection: true,
          })
        }
      }
    }
  )
}
