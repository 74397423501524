import fp from 'lodash/fp'

import { AppError } from 'compile-ui'

const appErrorMutations = AppError.storeMutations

export const state = () => {
  const initializer = fp.compose(
    AppError.storeInitializer
  )

  return initializer({})
}

export const mutations = {
  ...appErrorMutations,
}

export const actions = {}
