import Vue from 'vue'

Vue.filter('formatNumber', value => {
  let newValue = value
  if (value >= 1000) {
    let suffixes = ['', 'K', 'M', 'B', 'T']
    let suffixNum = Math.floor(('' + value).length / 3)
    let shortValue = ''
    /* eslint-disable */
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision))
      let dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '')
      if (dotLessShortValue.length <= 2) { break }
    }
    // let shortNum
    // // if (shortValue % 1 !== 0) shortNum = shortValue.toFixed(1)
    newValue = shortValue + suffixes[suffixNum]
    /* eslint-enable */
  }
  return newValue
})
